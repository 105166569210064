<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310.74 310.74">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <g>
          <circle class="cls-2" cx="155.37" cy="155.37" r="151.87" />
          <polyline class="cls-2" points="128.75 130.32 47.98 47.98 47.98 47.98 47.98 47.98" />
          <polyline class="cls-2" points="185.74 186.13 262.76 262.75 262.76 262.76 262.76 262.76" />
        </g>
        <g>
          <path class="cls-1" d="m152.4,61.78c-10.61,0-22.84,47.62,3.6,47.62,18.12,0,14.02-47.62-3.6-47.62Z" />
          <line class="cls-1" x1="153.54" y1="253.85" x2="153.54" y2="171.45" />
          <path
            class="cls-1"
            d="m150.01,112.27c-.44,4.4,4.04,7.75,8.26,6.42,10.43-3.27,17.15-10.49,26.74-28.54,8.51-16-31.9-8.68-35.01,22.12Z"
          />
          <path
            class="cls-1"
            d="m159.29,115.83c.44,4.4-4.04,7.75-8.26,6.42-10.43-3.27-17.15-10.49-26.74-28.54-8.51-16,31.9-8.68,35.01,22.12Z"
          />
          <path
            class="cls-1"
            d="m150.01,137.2c-.44,4.4,4.04,7.75,8.26,6.42,10.43-3.27,17.15-10.49,26.74-28.54,8.51-16-31.9-8.68-35.01,22.12Z"
          />
          <path
            class="cls-1"
            d="m159.29,140.76c.44,4.4-4.04,7.75-8.26,6.42-10.43-3.27-17.15-10.49-26.74-28.54-8.51-16,31.9-8.68,35.01,22.12Z"
          />
          <path
            class="cls-1"
            d="m150.01,162.12c-.44,4.4,4.04,7.75,8.26,6.42,10.43-3.27,17.15-10.49,26.74-28.54,8.51-16-31.9-8.68-35.01,22.12Z"
          />
          <path
            class="cls-1"
            d="m159.29,165.68c.44,4.4-4.04,7.75-8.26,6.42-10.43-3.27-17.15-10.49-26.74-28.54-8.51-16,31.9-8.68,35.01,22.12Z"
          />
          <path
            class="cls-1"
            d="m150.01,187.04c-.44,4.4,4.04,7.75,8.26,6.42,10.43-3.27,17.15-10.49,26.74-28.54,8.51-16-31.9-8.68-35.01,22.12Z"
          />
          <path
            class="cls-1"
            d="m159.29,190.61c.44,4.4-4.04,7.75-8.26,6.42-10.43-3.27-17.15-10.49-26.74-28.54-8.51-16,31.9-8.68,35.01,22.12Z"
          />
          <path
            class="cls-1"
            d="m150.01,211.97c-.44,4.4,4.04,7.75,8.26,6.42,10.43-3.27,17.15-10.49,26.74-28.54,8.51-16-31.9-8.68-35.01,22.12Z"
          />
          <path
            class="cls-1"
            d="m159.29,215.53c.44,4.4-4.04,7.75-8.26,6.42-10.43-3.27-17.15-10.49-26.74-28.54-8.51-16,31.9-8.68,35.01,22.12Z"
          />
          <line class="cls-1" x1="123.66" y1="187.44" x2="118.43" y2="180.81" />
          <line class="cls-1" x1="123.66" y1="162.52" x2="118.43" y2="155.89" />
          <line class="cls-1" x1="123.66" y1="137.59" x2="118.43" y2="130.97" />
          <line class="cls-1" x1="123.66" y1="112.67" x2="118.43" y2="106.04" />
          <line class="cls-1" x1="123.66" y1="87.74" x2="118.43" y2="81.12" />
          <line class="cls-1" x1="185.01" y1="83.39" x2="190.24" y2="76.76" />
          <line class="cls-1" x1="153.03" y1="61.78" x2="153.27" y2="53.34" />
          <line class="cls-1" x1="185.01" y1="108.31" x2="190.24" y2="101.69" />
          <line class="cls-1" x1="185.01" y1="133.24" x2="190.24" y2="126.61" />
          <line class="cls-1" x1="185.01" y1="158.16" x2="190.24" y2="151.53" />
          <line class="cls-1" x1="185.01" y1="183.08" x2="190.24" y2="176.46" />
        </g>
      </g>
    </g>
  </svg>
</template>

<style scoped>
.cls-1 {
  fill: #fff;
}

.cls-1,
.cls-2 {
  stroke: #267891;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 6.99px;
}

.cls-2 {
  fill: none;
}
</style>
